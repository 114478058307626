@tailwind base;
@tailwind components;
@tailwind utilities;


.cursor-hand {
    cursor: hand;
}

.p-datatable{
    @apply !bg-gray-900;
}
.p-datatable .p-datatable-thead>tr>th {
    @apply  !bg-white !font-normal !text-gray-900
}

:global(.dark) .p-datatable .p-datatable-thead>tr>th {
    @apply !bg-gray-900 text-gray-100;
}

h1 {
    @apply text-3xl;
}
h2 {
    @apply text-2xl;
}
h3 {
    @apply text-xl;
}
